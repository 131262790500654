<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 215px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th style="min-width: 150px">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                :default-value="filters.active"
                name="active"
                sort-name="active"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="min-width: 150px">
              <SelectFilter
                :options="warehouses"
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="min-width: 120px">
              <SelectFilter
                :options="owners"
                :label="$t('labels.contract_owner')"
                :placeholder="$t('labels.contract_owner')"
                name="id_owner"
                sort-name="id_owner"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="min-width: 120px">
              <SelectFilter
                :options="deliveryCompanies"
                :label="$t('labels.delivery_company')"
                :placeholder="$t('labels.delivery_company')"
                name="id_delivery_company"
                sort-name="id_delivery_company"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="min-width: 150px">
              <SelectFilter
                :options="eMarkets"
                :label="$t('labels.e_market')"
                :placeholder="$t('labels.e_market')"
                name="id_e_marketplace"
                sort-name="id_e_marketplace"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.api_cost')"
                :placeholder="$t('labels.api_cost')"
                name="api_cost"
                sort-name="api_cost"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 130px">
              <InputFilterFromTo
                :label="$t('labels.auto_optimize_provincial_cost')"
                :placeholder="$t('labels.auto_optimize_provincial_cost')"
                name="auto_optimize_provincial_cost"
                sort-name="auto_optimize_provincial_cost"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 130px">
              <InputFilterFromTo
                :label="$t('labels.auto_optimize_inter_provincial_cost')"
                :placeholder="$t('labels.auto_optimize_inter_provincial_cost')"
                name="auto_optimize_inter_provincial_cost"
                sort-name="auto_optimize_inter_provincial_cost"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 120px">
              <InputFilterFromTo
                :label="$t('labels.handover_fixed_cost')"
                :placeholder="$t('labels.handover_fixed_cost')"
                name="handover_fixed_cost"
                sort-name="handover_fixed_cost"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 120px">
              <InputFilterFromTo
                :label="$t('labels.handover_cost_per_gram')"
                :placeholder="$t('labels.handover_cost_per_gram')"
                name="handover_fixed_cost"
                sort-name="handover_fixed_cost"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 130px">
              <InputFilterFromTo
                :label="$t('labels.handover_min_cost')"
                :placeholder="$t('labels.handover_min_cost')"
                name="handover_min_cost"
                sort-name="handover_min_cost"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 135px">
              <InputFilterFromTo
                :label="$t('labels.receive_return_fixed_cost')"
                :placeholder="$t('labels.receive_return_fixed_cost')"
                name="receive_return_fixed_cost"
                sort-name="receive_return_fixed_cost"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 130px">
              <InputFilterFromTo
                :label="$t('labels.receive_return_cost_per_gram')"
                :placeholder="$t('labels.receive_return_cost_per_gram')"
                name="receive_return_cost_per_gram"
                sort-name="receive_return_cost_per_gram"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 150px">
              <InputFilterFromTo
                :label="$t('labels.receive_return_min_cost')"
                :placeholder="$t('labels.receive_return_min_cost')"
                name="receive_return_min_cost"
                sort-name="receive_return_min_cost"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 130px">
              <InputFilterFromTo
                :label="$t('labels.decrease_weight_provincial')"
                :placeholder="$t('labels.decrease_weight_provincial')"
                name="decrease_weight_provincial"
                sort-name="decrease_weight_provincial"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 130px">
              <InputFilterFromTo
                :label="$t('labels.decrease_weight_inter_provincial')"
                :placeholder="$t('labels.decrease_weight_inter_provincial')"
                name="decrease_weight_inter_provincial"
                sort-name="decrease_weight_inter_provincial"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 140px">
              <InputFilterFromTo
                :label="$t('labels.fixed_weight_provincial')"
                :placeholder="$t('labels.fixed_weight_provincial')"
                name="fixed_weight_provincial"
                sort-name="fixed_weight_provincial"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 140px">
              <InputFilterFromTo
                :label="$t('labels.fixed_weight_inter_provincial')"
                :placeholder="$t('labels.fixed_weight_inter_provincial')"
                name="fixed_weight_inter_provincial"
                sort-name="fixed_weight_inter_provincial"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 140px">
              <InputFilterFromTo
                :label="$t('labels.min_weight_after_decrease_provincial')"
                :placeholder="$t('labels.min_weight_after_decrease_provincial')"
                name="min_weight_after_decrease_provincial"
                sort-name="min_weight_after_decrease_provincial"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 140px">
              <InputFilterFromTo
                :label="$t('labels.min_weight_after_decrease_inter_provincial')"
                :placeholder="
                  $t('labels.min_weight_after_decrease_inter_provincial')
                "
                name="min_weight_after_decrease_inter_provincial"
                sort-name="min_weight_after_decrease_inter_provincial"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 120px">
              <InputFilterFromTo
                :label="$t('labels.postage_insured_percent')"
                :placeholder="$t('labels.postage_insured_percent')"
                name="postage_insured_percent"
                sort-name="postage_insured_percent"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 120px">
              <InputFilterFromTo
                :label="$t('labels.postage_insured_from_amount')"
                :placeholder="$t('labels.postage_insured_from_amount')"
                name="postage_insured_from_amount"
                sort-name="postage_insured_from_amount"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 120px">
              <InputFilterFromTo
                :label="$t('labels.cod_cost_percent')"
                :placeholder="$t('labels.cod_cost_percent')"
                name="cod_cost_percent"
                sort-name="cod_cost_percent"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 120px">
              <InputFilterFromTo
                :label="$t('labels.cod_cost_from')"
                :placeholder="$t('labels.cod_cost_from')"
                name="cod_cost_from"
                sort-name="cod_cost_from"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <v-btn
                color="success"
                small
                @click="addConfig"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`${index}_${item.id}`"
            class="text-center"
          >
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <v-autocomplete
                :disabled="!item.editing"
                class="c-input-xs"
                v-model="item.id_warehouse"
                :items="warehouses"
                :placeholder="$t('labels.warehouse')"
                dense
                outlined
                clearable
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                :disabled="!item.editing"
                class="c-input-xs"
                v-model="item.id_owner"
                :items="owners"
                :placeholder="$t('labels.contract_owner')"
                dense
                outlined
                clearable
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                :disabled="!item.editing"
                class="c-input-xs"
                v-model="item.id_delivery_company"
                :items="deliveryCompanies"
                :placeholder="$t('labels.delivery_company')"
                dense
                outlined
                clearable
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                :disabled="!item.editing"
                class="c-input-xs"
                v-model="item.id_e_marketplace"
                :items="eMarkets"
                :placeholder="$t('labels.e_market')"
                dense
                outlined
                clearable
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.api_cost"
                :options="moneyOptions"
                type="number"
                :disabled="!item.editing || item.id_owner === 0"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></vuetify-money>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.auto_optimize_provincial_cost"
                :options="moneyOptions"
                type="number"
                :disabled="!item.editing || item.id_owner === 0"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></vuetify-money>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.auto_optimize_inter_provincial_cost"
                :options="moneyOptions"
                type="number"
                :disabled="!item.editing || item.id_owner === 0"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></vuetify-money>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.handover_fixed_cost"
                :options="moneyOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
                @input="
                  changeItemHandoverCost(item, index, 'handover_fixed_cost')
                "
              ></vuetify-money>
            </td>
            <td>
              <v-text-field
                v-model.number="item.handover_cost_per_gram"
                type="number"
                :disabled="!item.editing"
                class="c-input-small c-input-mini-append-icon"
                append-icon="đ/g"
                outlined
                dense
                hide-details
                single-line
                @input="
                  changeItemHandoverCost(item, index, 'handover_cost_per_gram')
                "
              ></v-text-field>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.handover_min_cost"
                :options="moneyOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
                @input="
                  changeItemHandoverCost(item, index, 'handover_min_cost')
                "
              ></vuetify-money>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.receive_return_fixed_cost"
                :options="moneyOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
                @input="
                  changeItemReceiveReturnCost(
                    item,
                    index,
                    'receive_return_fixed_cost'
                  )
                "
              ></vuetify-money>
            </td>
            <td>
              <v-text-field
                v-model.number="item.receive_return_cost_per_gram"
                type="number"
                :disabled="!item.editing"
                class="c-input-small c-input-mini-append-icon"
                append-icon="đ/g"
                outlined
                dense
                hide-details
                single-line
                @input="
                  changeItemReceiveReturnCost(
                    item,
                    index,
                    'receive_return_cost_per_gram'
                  )
                "
              ></v-text-field>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.receive_return_min_cost"
                :options="moneyOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
                @input="
                  changeItemReceiveReturnCost(
                    item,
                    index,
                    'receive_return_min_cost'
                  )
                "
              ></vuetify-money>
            </td>
            <td>
              <v-text-field
                v-model.number="item.decrease_weight_provincial"
                type="number"
                :disabled="!item.editing"
                class="c-input-small c-input-mini-append-icon"
                append-icon="g"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model.number="item.decrease_weight_inter_provincial"
                type="number"
                :disabled="!item.editing"
                class="c-input-small c-input-mini-append-icon"
                append-icon="g"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.fixed_weight_provincial"
                :options="moneyOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></vuetify-money>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.fixed_weight_inter_provincial"
                :options="moneyOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></vuetify-money>
            </td>
            <td>
              <v-text-field
                v-model.number="item.min_weight_after_decrease_provincial"
                type="number"
                :disabled="!item.editing"
                class="c-input-small c-input-mini-append-icon"
                append-icon="g"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model.number="item.min_weight_after_decrease_inter_provincial"
                type="number"
                :disabled="!item.editing"
                class="c-input-small c-input-mini-append-icon"
                append-icon="g"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model.number="item.postage_insured_percent"
                type="number"
                :disabled="!item.editing"
                class="c-input-small c-input-mini-append-icon"
                append-icon="%"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.postage_insured_from_amount"
                :options="moneyOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></vuetify-money>
            </td>
            <td>
              <v-text-field
                v-model.number="item.cod_cost_percent"
                type="number"
                :disabled="!item.editing"
                class="c-input-small c-input-mini-append-icon"
                append-icon="%"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <vuetify-money
                v-model.number="item.cod_cost_from"
                :options="moneyOptions"
                type="number"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                single-line
              ></vuetify-money>
            </td>
            <td>
              <v-btn
                v-if="!item.editing"
                x-small
                color="warning"
                @click="toggleEditing(item, index, true)"
              >
                {{ $t("labels.edit") }}
              </v-btn>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="updateConfig(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
              <br />
              <v-btn
                x-small
                color="info"
                class="mt-1"
                v-if="item.id_owner === 0 && item.id_delivery_company"
                @click="showDeliveryAccount(item)"
              >
                {{ $t("labels.account") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDeliveryAccount" max-width="400px">
      <DeliveryAccount
        v-if="dialogDeliveryAccount"
        :updating-item="updatingItem"
        @cancel="hideDeliveryAccount"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { GRAM_OPTIONS, MONEY_OPTIONS, YES_NO_OPTIONS } from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "Contract",
  components: {
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    DeliveryAccount: () => import("@/components/delivery/DeliveryAccount"),
  },
  data: () => ({
    isLoading: false,
    page: 1,
    totalPage: 1,
    items: [],
    filters: {},
    warehouses: [],
    deliveryCompanies: [],
    eMarkets: [],
    owners: [],
    yesNoOptions: [...YES_NO_OPTIONS],
    costTypeOptions: [],
    moneyOptions: { ...MONEY_OPTIONS },
    gramOptions: { ...GRAM_OPTIONS },
    updatingItem: {},
    dialogDeliveryAccount: false,
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    const costTypeOptions = [
      {
        text: this.$t("labels.handover_cost_type_0"),
        value: 0,
      },
      {
        text: this.$t("labels.handover_cost_type_1"),
        value: 1,
      },
      {
        text: this.$t("labels.handover_cost_type_2"),
        value: 2,
      },
    ];
    this.costTypeOptions = [...costTypeOptions];
  },
  mounted() {
    this.getList();
    this.getWarehouses();
    this.getDeliveryCompanies();
    this.getEMarkets();
    this.getOwners();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showDeliveryAccount(item) {
      this.updatingItem = { ...item };
      this.dialogDeliveryAccount = true;
    },
    hideDeliveryAccount() {
      this.updatingItem = {};
      this.dialogDeliveryAccount = false;
    },
    async getList() {
      const { data } = await httpClient.post("/delivery/v1/get-contracts", {
        ...this.filters,
        page: this.page,
      });
      this.totalPage = data.totalPage;
      this.items = [...data.rows].map((item) => ({
        ...item,
        editing: false,
      }));
    },
    async getWarehouses() {
      const { data } = await httpClient.post("/warehouse/v1/get-all");
      this.warehouses = data.map((e) => ({
        value: e.id,
        text: e.code_name || "",
      }));
    },
    async getDeliveryCompanies() {
      const { data } = await httpClient.post(
        "/common/v1/get-delivery-company",
        { active: 1 }
      );
      this.deliveryCompanies = data.map((e) => ({
        value: e.id,
        text: e.short_name || "",
      }));
    },
    changeItemHandoverCost(item, index, type) {
      if (item[type]) {
        if (["handover_fixed_cost"].includes(type)) {
          item.handover_cost_per_gram = null;
          item.handover_min_cost = null;
        } else if (
          ["handover_cost_per_gram", "handover_min_cost"].includes(type)
        ) {
          item.handover_fixed_cost = null;
        }
        const items = [...this.items];
        items[index] = item;
        this.items = [...items];
      }
    },
    changeItemReceiveReturnCost(item, index, type) {
      if (item[type]) {
        if (["receive_return_fixed_cost"].includes(type)) {
          item.receive_return_cost_per_gram = null;
          item.receive_return_min_cost = null;
        } else if (
          ["receive_return_cost_per_gram", "receive_return_min_cost"].includes(
            type
          )
        ) {
          item.receive_return_fixed_cost = null;
        }
        const items = [...this.items];
        items[index] = item;
        this.items = [...items];
      }
    },
    async getOwners() {
      const { data } = await httpClient.post(
        "/customer/v1/get-all-has-contract",
        { is_active: true }
      );
      const options = data.map((e) => ({
        value: e.id,
        text: e.company_name || "",
      }));
      const defaultOptions = [
        {
          value: 0,
          text: "SSC",
        },
        {
          value: -1,
          text: "Không xác định",
        },
      ];
      this.owners = [...defaultOptions, ...options];
    },
    async getEMarkets() {
      const { data } = await httpClient.post("/common/v1/get-e-market", {
        active: 1,
      });
      this.eMarkets = data.map((e) => ({
        value: e.id,
        text: e.short_name || "",
      }));
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        id_warehouse: null,
        id_customer: null,
        id_owner: null,
        id_e_marketplace: null,
        id_delivery_company: null,
        handover_fixed_cost: null,
        handover_cost_per_gram: null,
        handover_min_cost: null,
        receive_return_fixed_cost: null,
        receive_return_cost_per_gram: null,
        receive_return_min_cost: null,
        decrease_weight_provincial: null,
        decrease_weight_inter_provincial: null,
        fixed_weight_provincial: null,
        fixed_weight_inter_provincial: null,
        min_weight_after_decrease_provincial: null,
        min_weight_after_decrease_inter_provincial: null,
        postage_insured_percent: null,
        postage_insured_from_amount: null,
        cod_cost_percent: null,
        cod_cost_from: null,
        api_cost: null,
        auto_optimize_provincial_cost: null,
        auto_optimize_inter_provincial_cost: null,
        notify_email: null,
        account_info: null,
        status: true,
        editing: true,
      });
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/delivery/v1/update-contract", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
